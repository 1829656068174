<template>
  <section class="pt-4 px-0 px-xl-4 h-100">
    <b-container fluid>
      <b-row class="pb-3">
        <b-col cols="3">
          <b-input
            v-model="surplusStock"
            type="number"
            placeholder="Eccedenza Stock"
          />
        </b-col>
        <b-col cols="3">
          <b-input
            v-model="surplusValueStock"
            type="number"
            step="0.01"
            placeholder="Eccedenza Valore Stock"
          />
        </b-col>
        <b-col cols="3">
          <b-input
            v-model="surplusStockDays"
            type="number"
            placeholder="Eccedenza Stock (gg)"
          />
        </b-col>
        <b-col cols="3">
          <b-button @click="getTurnover">Filtra</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          v-model="visibleRows"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle m-2" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template slot="top-row">
            <td colspan="12" class="text-right">Total</td>
            <td>
              <strong>{{ currencyFormatter(totalStock) }}</strong>
            </td>
            <td colspan="1"></td>
            <td />
          </template>
          <template #cell(surplusValueStock)="data">
            {{ currencyFormatter(data.item.surplusValueStock) }}
          </template>
          <template #cell(surplusStockDays)="data">
            {{
              data.item.surplusStockDays === 999999999999
                ? "INF"
                : data.item.surplusStockDays
            }}
          </template>
          <template #cell(salesPerPeriod)="data">
            {{ data.item.salesPerPeriod.toFixed(3) }}
          </template>
          <template #cell(safetyStock)="data">
            {{
              data.item.safetyStock
                ? data.item.safetyStock.toFixed(3)
                : data.item.safetyStock
            }}
          </template>
        </b-table>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Turnover",
  data() {
    return {
      fields: [
        { key: "brand", label: "Brand", sortable: true },
        { key: "code", label: "Codice", sortable: true },
        { key: "name", label: "Descrizione" },
        { key: "costPrice", label: "Prezzo Netto" },
        {
          key: "arrivalDate",
          label: "Data Arrivo",
          thStyle: { width: "120px" }
        },
        {
          key: "lastOrderDate",
          label: "Data Ultimo Acq.",
          thStyle: { width: "120px" }
        },
        { key: "salesPerPeriod", label: "Consumo Giornaliero" },
        { key: "goodOnHand", label: "Q.tà Inv." },
        { key: "purchaseQuantity", label: "Q.tà Imp." },
        { key: "safetyStock", label: "Stock Sicurezza" },
        { key: "restockingLevel", label: "Livello Riordino" },
        { key: "surplusStock", label: "Eccedenza Stock", sortable: true },
        {
          key: "surplusValueStock",
          label: "Eccedenza Valore Stock",
          sortable: true
        },
        {
          key: "surplusStockDays",
          label: "Eccedenza Stock (gg)",
          sortable: true
        }
      ],
      items: [],
      visibleRows: [],
      isBusy: false,
      surplusStock: null,
      surplusValueStock: null,
      surplusStockDays: null
    };
  },
  mounted() {
    this.getTurnover();
  },
  watch: {
    items(value) {
      if (value.length > 0) this.isBusy = false;
    }
  },
  computed: {
    totalStock() {
      return this.visibleRows.reduce((accum, item) => {
        return accum + item.surplusValueStock;
      }, 0);
    }
  },
  methods: {
    getTurnover: function() {
      this.isBusy = true;
      this.items = [];
      let endPointUrl = "turnover/discount";
      if (this.surplusStock || this.surplusValueStock || this.surplusStockDays)
        endPointUrl += "?";
      if (this.surplusStock)
        endPointUrl += "minimalSurplusStock=" + this.surplusStock + "&";
      if (this.surplusValueStock)
        endPointUrl +=
          "minimalSurplusValueStock=" +
          Number(this.surplusValueStock).toFixed(2) +
          "&";
      if (this.surplusStockDays)
        endPointUrl += "minimalSurplusStockDays=" + this.surplusStockDays + "&";
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + endPointUrl)
        .then(res => {
          this.items = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    currencyFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR"
      });
      return currencyFormatter.format(number);
    }
  }
};
</script>
